import React from "react";

export const EmbeddedForm35 = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://formular230.ro/share/73d894a25b6"; // Replace with your script URL
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup the script when the component unmounts
    };
  }, []);

  return (
    <div>
      <div className="f230ro-formular"></div>
    </div>
  );
};
