import React from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

export const Article = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  const [details, setDetails] = React.useState({});

  React.useLayoutEffect(() => {
    fetch(
      `${process.env.REACT_APP_STRAPI_URL}/api/articles/${id}?populate=deep&locale=${i18n.language}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => res.json())
      .then(({ data }) => {
        const { attributes } = data;

        setDetails(attributes);
      })
      .catch((res) => {
        console.log("ERROR");

        navigate("/blog");
      });
  }, [i18n.language]);

  return (
    <div className="single-article-big-wrapper">
      <div className="hero-section">
        <div
          className="hero-section-bg-image"
          style={
            details?.image?.data
              ? {
                  background: `url(${details.image.data.attributes.formats.medium.url}) center / cover no-repeat`,
                }
              : { background: "none" }
          }
        />
      </div>
      <div className="message">
        <div className="inner-wrapper">{details.title}</div>
      </div>
      <div className="single-article-content-wrapper">
        <ReactMarkdown
          children={details.content}
          // remarkPlugins={[remarkGfm]}
        />
      </div>
    </div>
  );
};
