import React from "react";
import { EmbeddedForm35 } from "./EmbeddedForm35";
import { useTranslation } from "react-i18next";

export const Redirect35 = () => {
  const { t } = useTranslation();
  return (
    <div className="redirect-35-page">
      <div className="hero-section">
        <div className="hero-section-bg-image" />
        <div className="message">
          {t("redirectPage.textOverImage")}
        </div>
      </div>

      <div className="redirect-35-wrapper">
        <EmbeddedForm35 />
      </div>
    </div>
  );
};
